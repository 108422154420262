<template>
  <div id="app" class="container">
    <!-- 左侧聊天列表 -->
    <div class="chat-list">
      <div v-for="(chat, index) in chats" :key="index" class="chat-item" @click="selectChat(index)" :class="{ active: selectedChatIndex === index }">
        <img class="chat-avatar" :src="chat.avatar" alt="avatar" />
        <div class="chat-info">
          <div class="chat-name">{{ chat.name }}</div>
          <div class="last-message" v-if="chat.messages.length>0">{{ chat.messages[chat.messages.length - 1].text }}</div>
        </div>
      </div>
    </div>

    <!-- 右侧聊天记录 -->
    <div class="chat-box">
      <div class="chat-header">
        <h3>{{ selectedChat ? selectedChat.name : '请选择一个聊天' }}</h3>
      </div>

      <div class="messages" ref="messages">
        <template v-if="chats[selectedChatIndex].messages.length>0">
          <div v-for="(message, index) in chats[selectedChatIndex].messages" :key="index" class="message_content_item">
            <!-- 自己的 -->
            <div v-if="message.sender === 'me'" class="message_item message_item_left ">
              <img class="avatar" style="margin-right:15px" src="@/assets/logo.png" alt="">
              <div class="message sent" v-if="handlerText( message.text)">
                <img :src="handlerImg(message.text)" alt="">
              </div>
              <div class="message sent" v-else>
                {{message.text}}
              </div>

            </div>
            <!-- 对方的 -->
            <div v-else class="message_item message_item_right">

              <div class="message received" v-if="handlerText( message.text)">
                <img :src="handlerImg(message.text)" alt="">
              </div>
              <div class="message received" v-else>
                {{message.text}}
              </div>
              <img class="avatar" style="margin-left:15px" src="@/assets/logo.png" alt="">
            </div>
          </div>
        </template>
      </div>

      <div class="chat-input">
        <div class="chatOptions">
          <div class="icon">
            <!-- 图片上传 -->
            <ImageUploader @uploadFile="uploadFile"></ImageUploader>
          </div>
          <div class="icon">
            <!-- 表情 -->
            <Expression @addEmoji="addEmoji"></Expression>
          </div>

        </div>
        <el-input type="textarea" :rows="4" v-model="newMessage" @keyup.enter="sendMessage" placeholder="请输入消息...">
        </el-input>

        <div class="sendMsg_btn" @click="sendMessage">
          发送
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo } from "@/utils/api.js";
import ImageUploader from "@/components/ImageUploader.vue";
import Expression from "@/components/expression.vue";
import axios from "axios";
export default {
  components: { ImageUploader, Expression },
  data() {
    return {
      newMessage: "",
      selectedChat: {},
      chats: [
        {
          name: "管理员",
          avatar: "https://randomuser.me/api/portraits/men/1.jpg",
          messages: [],
        },
      ],
      selectedChatIndex: 0, // 当前选中的聊天会话
      ws: {},
      myMessageId: "",
      uuid: "",
    };
  },
  async created() {
    this.selectedChat = this.chats[this.selectedChatIndex];
    const res = await getInfo();
    this.uuid = res.data.name;

    if (res.code == 200) {
      this.initWebSocket();
    }
  },

  methods: {
    initWebSocket() {
      let that = this;

      // this.ws = new WebSocket("ws://localhost:8001/ws/" + this.uuid);
      this.ws = new WebSocket("wss://chatadmin.lwsff.cn/ws/" + this.uuid);
      // 连接成功后的回调函数
      this.ws.onopen = function (params) {
        console.log(params, "连接成功后的回调函数");
      };

      // 从服务器接受到信息时的回调函数
      this.ws.onmessage = (e) => {
        let msg = JSON.parse(e.data);
        console.log(msg);
        const newMessage = {
          text: msg.content,
          sender: "to",
        };
        this.myMessageId = msg.id;

        // push 进去数据
        this.chats[0].messages.push(newMessage);
      };

      // 连接关闭后的回调函数
      this.ws.onclose = (evt) => {
        console.log("关闭客户端连接", evt);
      };

      // 连接失败后的回调函数
      this.ws.onerror = (evt) => {
        console.log("连接失败了");
      };
    },
    // 图片上传回调
    uploadFile(data) {
      const newMessage = {
        text: "图片" + data.url,
        sender: "me",
        id: "99999", // 接收方的id
        sendid: this.myMessageId, // 发送人自己的id
        // id
      };
      console.log(newMessage, "newMessage");
      // 发送消息
      this.webSocketMsg(newMessage);
      this.chats[this.selectedChatIndex].messages.push(newMessage);
      this.$nextTick(() => {
        const messagesContainer = this.$refs.messages;
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      });
    },

    // 检测图片
    handlerText(text) {
      return text.includes("图片");
    },
    handlerImg(text) {
      return text.replace("图片", "");
    },

    // 发送消息
    sendMessage() {
      if (this.newMessage.trim() && this.selectedChatIndex !== null) {
        const newMessage = {
          text: this.newMessage,
          sender: "me",
          id: "99999", // 接收方的id
          sendid: this.myMessageId, // 发送人自己的id
          // id
        };
        // 发送消息
        this.webSocketMsg(newMessage);
        this.chats[this.selectedChatIndex].messages.push(newMessage);
        this.newMessage = "";
        // 确保消息区域滚动到底部
        this.$nextTick(() => {
          const messagesContainer = this.$refs.messages;
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        });
      }
    },
    webSocketMsg(newMessage) {
      // 正在连接
      if (this.ws.readyState == 1) {
        this.ws.send(JSON.stringify(newMessage));
      } else {
      }
    },

    addEmoji(e) {
      this.newMessage = this.newMessage + e.native;
    },
  },
};
</script>

<style scoped  lang="scss">
/* 页面布局 */
.container {
  display: flex;
  height: 100vh;
}

.chat-list {
  width: 300px;
  background-color: #fafafa;
  padding: 20px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  flex-shrink: 0;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.chat-item:hover {
  background-color: #e0f7fa;
}

.chat-item.active {
  background-color: #e0e0e0;
}

.chat-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-info .chat-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.chat-info .last-message {
  font-size: 14px;
  color: #888;
}

/* 右侧聊天内容 */
.chat-box {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100vh;
}

.chat-header {
  background-color: #4caf50;
  color: white;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  text-align: center;
}

.messages {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
  background-color: #f9f9f9;
}

.message_content_item {
  max-width: 100%;
  margin: 10px 0;
  display: flex;
}
.message_item {
  flex: 1;
  width: 100%;
  display: flex;
}
.message_item_left {
  display: flex;
  justify-content: flex-start;
}
.message_item_right {
  display: flex;
  justify-content: flex-end;
}
.message {
  max-width: 80%;
  padding: 12px;
  border-radius: 15px;
  background-color: #e0e0e0;
  word-wrap: break-word;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.message.sent {
  display: flex;
  background-color: #4caf50 !important;
  color: white;
  font-size: 13px;
  align-self: flex-end; /* 发送的消息右对齐 */
}

.message.received {
  display: flex;
  background-color: #f1f1f1;
  color: #333;
  justify-content: flex-end;
}

/* 输入框区域 */
.chat-input {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 120px;
  border-top: 1px solid #ddd;
  background-color: #fafafa;
  .sendMsg_btn {
    position: absolute;
    right: 2%;
    bottom: 10%;
    padding: 12px 20px;
    background-color: #4caf50;
    border: none;
    border-radius: 25px;
    color: white;
    margin-left: 10px;
    cursor: pointer;
    font-size: 16px;
  }
}

.chat-input input {
  flex: 1;
  padding: 12px 18px;
  border-radius: 25px;
  border: 1px solid #ddd;
  outline: none;
  font-size: 16px;
  background-color: #fff;
}

.chat-input button:hover {
  background-color: #45a049;
}
.chatOptions {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
  .icon {
    margin-right: 15px;
  }
}

::v-deep .el-textarea {
  background: #fafafa !important;
  border: none !important;
}
::v-deep .el-textarea__inner {
  background: #fafafa !important;
  border: none !important;
}
</style>
