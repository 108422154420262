<template>
  <div>
    <label for="image-upload" class="upload-icon">
      <i class="el-icon-picture"></i>
    </label>
    <input id="image-upload" type="file" accept="image/*" @change="handleFileChange" style="display: none;" />
    <img v-if="imageUrl" :src="imageUrl" alt="Uploaded Image" class="uploaded-image" />
  </div>
</template>

<script>
import { upload } from "@/utils/api";
export default {
  data() {
    return {
      imageUrl: null,
    };
  },
  methods: {
    async handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        let formData = new FormData();
        formData.append("file", file);
        const res = await upload(formData);

        let data = {
          url: process.env.VUE_APP_IMG_URL + res.data.url,
          name: res.data.name,
        };
        this.$emit("uploadFile", data);

        // const reader = new FileReader();
        // reader.onload = (e) => {
        //   this.imageUrl = e.target.result;
        // };
        // reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.upload-icon {
  font-size: 20px;
  cursor: pointer;
  // color: #007bff;
}

.uploaded-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}
</style>