import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'

axios.defaults.withCredentials = true
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000 // request timeout
})
// 添加请求拦截器
service.interceptors.request.use(
  config => {
    // console.log(process.env.VUE_APP_BASE_API,'process.env.VUE_APP_BASE_API');
    if (store.getters.token) {
      // config.headers['Token'] = getToken()
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(response => {
  // console.log("发送请求 ");
  // 二进制数据不拦截
  if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
    return response.data
  }

  const res = response.data
  // console.log(res,'拦截器');
  if (res.code !== 200) { // 异常状态码返回处理


    if (res.code == 400) {
      Message({
        message: '登录过期，请重新登陆',
        type: 'error',
        duration: 5 * 1000
      })
      store.dispatch('user/logout')

    }
    else {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    }
    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: 'Re-Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        // store.dispatch('user/resetToken').then(() => {
        //   location.reload()
        // })
      })
    }

    Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    })


    return Promise.reject(new Error(res.msg || 'Error'))
  } else {
    return res
  }

},
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
