import request from './request'

// 
export function getInfo(data) {
  return request({
    url: 'chat/getLocal',
    method: 'post',
    data
  })
}
export function userLogin(data) {
  return request({
    url: 'chat/login',
    method: 'post',
    data
  })
}


export function SettingID(data) {
  return request({
    url: 'chat/SettingID',
    method: 'post',
    data
  })
}
export function upload(data) {
  return request({
    url: 'chat/upload',
    method: 'post',
    data
  })
}

