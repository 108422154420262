<template>
  <!-- 表情 -->
  <div class="disBox">
    <i class="el-icon-picture-outline-round" @click="disExpress = !disExpress"></i>
    <div class="picker_dis">
      <template v-if="disExpress">
        <picker :include="['people','Smileys']" :showSearch="false" :showPreview="false" :showCategories="false" @select="addEmoji" />
      </template>
    </div>

  </div>
</template>

<script >
import { Picker } from "emoji-mart-vue"; //引入组件
export default {
  components: {
    //注册组件，不能全局挂载
    Picker,
  },
  data() {
    return {
      content: "",
      disExpress: false,
    };
  },
  methods: {
    addEmoji(e) {
      console.log(e);
      this.content += e.native;
      this.$emit("addEmoji", e);
      this.disExpress = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-picture-outline-round {
  font-size: 20px;
  cursor: pointer;
  // color: #007bff;
}
.emoji-mart[data-v-7bc71df8] {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 420px;
  color: #ffffff !important;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}
.disBox {
  position: relative;
  .picker_dis {
    position: absolute;
    top: -431px;
  }
}
</style>
