import Vue from 'vue'
import VueRouter from 'vue-router'
import user from '@/views/user.vue'
import admin from '@/views/admin.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: user
  },
  {
    path: '/admin',
    name: 'admin',
    component: admin
  }

]

const router = new VueRouter({
  routes
})

export default router
